// extracted by mini-css-extract-plugin
export var callToAction = "landing-module--call-to-action--bee89";
export var feature = "landing-module--feature--8715a";
export var featureGrid = "landing-module--feature-grid--92b75";
export var featureLogo = "landing-module--feature-logo--66619";
export var featureTitle = "landing-module--feature-title--aadbc";
export var footer = "landing-module--footer--738df";
export var gatsbyNoscript = "landing-module--gatsby-noscript--483d4";
export var gatsbyRespImageLink = "landing-module--gatsby-resp-image-link--c788e";
export var header = "landing-module--header--9251d";
export var headerImage = "landing-module--header-image--8b8e0";
export var headerImageLeft = "landing-module--header-image-left--339d3";
export var headerImageRight = "landing-module--header-image-right--0402d";
export var inlineCode = "landing-module--inline-code--040ea";
export var logo = "landing-module--logo--47fd4";
export var logoExplosion = "landing-module--logo-explosion--8b4cb";
export var mdOnly = "landing-module--md-only--06b41";
export var root = "landing-module--root--d082c";
export var section = "landing-module--section--ce37e";
export var sectionHeader = "landing-module--section-header--0e6f4";
export var sectionNarrow = "landing-module--section-narrow--357aa";
export var sectionTitle = "landing-module--section-title--6f26b";
export var slogan = "landing-module--slogan--d0d67";
export var smOnly = "landing-module--sm-only--ff86d";